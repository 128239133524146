import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Heading from "../components/heading"
import Breadcrumb from "../components/breadcrumb"
import ValueProp from '../components/valueProposition'
import Quote from '../components/Quote'
import CallToAction from "../components/CallToAction"

// We help small businesses realise their full potential through the use of cutting-edge technologies, enabled by our deep industry knowledge of technological challenges and your business's commercial context.
// Let us manage your middle and back office technology functions.

const pages = [
  { name: 'About Us', href: '/about-us', current: true },
]

const AboutUsPage = () => {
  return (
    <Layout
      title="Dimension Lab | About Us"
      description="A boutique web designer based out of Melbourne Australia, Dimension Lab specialises in using modern technology solutions to enable small businesses to expand their digital footprint."
      pageUrl="/about-us"
      theme='light'
      logo={
        <StaticImage
          src="../images/lightLogo.svg"
          alt="Workflow"
          placeholder="none"
          loading="eager"
          height={50}
        />
      }
    >
      {
        // Header Section
        <Heading
          heading="About Us"
          subheading="Dimension Lab"
          bg={
            <StaticImage
              src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80"
              alt="Banner for the About Us Page"
              placeholder="none"
              loading="eager"
              objectFit="fullWidth"
              objectPosition="center"
              className="h-full object-cover"
            />
          }
        />
      }
      {
        <Breadcrumb pages={pages} />
      }

      {
        // Content Section
        <div className="px-4">
          <div className="prose prose-lg prose-indigo mx-auto text-md text-gray-500 lg:text-base">
            <h2>About Dimension Lab</h2>
            <p className="mt-8 text-md font-semibold text-secondary-600">We are a boutique / custom website designer & builder based out of Melbourne, Australia.</p>
            <p className="text-md font-semibold text-secondary-400">We believe that websites are an enabler of business, and we provide the means for people to focus on what matters - their offering, their brand, their people.</p>
          </div>
          <div className="prose prose-lg prose-indigo mx-auto mt-6 text-md text-gray-500 lg:text-base">
            <h2>Our Story</h2>
            <p>Our story began after hearing the challenges of local businesses trying to manage their website investments.</p>
            <p>We identified key themes arising from many of the questions asked by many of our small business clients, including:</p>
            <ul>
              <li>Why should I invest in a website?</li>
              <li>What does a website give me?</li>
              <li>How do I make sure my business can quantify the beneifts of a website investment?</li>
              <li>How do I enable my website and other technology needs to support my business as it grows over time?</li>
            </ul>
            <p>Many of our clients have initially started their journey with an off-the-shelf website builder solution (e.g. Squarespace, Wix).</p>
            <p>Over time, these platforms could not support their ongoing growth, and they required a solution that enabled them to reach new heights in their business.</p>
          </div>
          <div className="prose prose-lg prose-indigo mx-auto mt-6 text-md text-gray-500 lg:text-base">
            <h2>What we provide to our clients</h2>
            <p><strong>A website should not be an afterthought for business owners looking to scale up their product or service.</strong></p>
            <p>We provide a <strong className="text-md font-semibold text-primary-400">fully bespoke and customisable technology solution</strong> for our clients, featuring:</p>
            <ul>
              <li>A <strong className="text-md font-semibold text-gray-600">bespoke-looking result</strong> for our clients websites</li>
              <li>A platform foundation that is built on the cloud, to provide you with a <strong className="text-md font-semibold text-gray-600">cost-effective</strong> and <strong className="text-md font-semibold text-gray-600">scalable</strong> solution</li>
              <li><strong className="text-md font-semibold text-gray-600">Tiers that offer a range of support and maintenance levels</strong> based on common client requirements</li>
            </ul>

            {/* <h2>From beginner to expert in 30 days</h2>
              <p>
                Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh.
                Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed tellus
                mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam turpis
                ipsum eu a sed convallis diam.
              </p>
              <blockquote>
                <p>
                  Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur neque tristique
                  pellentesque. Blandit amet, sed aenean erat arcu morbi.
                </p>
              </blockquote>
              <p>
                Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae
                sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit.
              </p>
              <figure>
                <img
                  className="w-full rounded-lg"
                  src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3"
                  alt=""
                  width={1310}
                  height={873}
                />
                <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption>
              </figure> */}
          </div>
        </div>
      }
      <ValueProp />
      <Quote />
      <CallToAction />
    </Layout>
  )
}

export default AboutUsPage
